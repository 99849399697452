import React, { Component } from 'react';
import { Provider } from 'react-redux';
import Layout from './components/layout';
import AppRenderer from './app-renderer';
import { configureStore } from './redux/store';
import './app.scss';
export default class App extends Component {
  static displayName = App.name;

  render() {
    console.log('v_1.9.3');
    return (
      <Provider store={configureStore()}>
        <div>
          <Layout>
            {/* <Suspense fallback={<div className="loading" />}>
              <Routes />
            </Suspense> */}
            <AppRenderer />
          </Layout>
        </div>
      </Provider>
    );
  }
}